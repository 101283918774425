<template>
  <div class="informationDetails">
    <cms-header></cms-header>
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w  clearfix cms-container">
        <div class="now_add left clearfix">
          <router-link class="now_a left" to="/">首页</router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link class="now_a left" to="/help">帮助中心</router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <a class="now_a left">帮助详情</a>
        </div>
      </div>
      <span class="child_name">帮助详情</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <div class="news_child">
          <div class="child_title">
            <span class="child_span">{{ content.contentTitle }}</span>
            <span class="child_time">{{ content.contentDatetime }}</span>
          </div>
          <div class="child_content" v-html="content.contentDetails">
          </div>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>

import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";

export default {
  name: "informationDetails",
  components: {CmsFoot, CmsHeader,},
  data() {
    return {
      content: {},
      configForm: {},
      query: {},
    }
  }, methods: {
    getConfigs: function (id) {
      let that = this;
      this.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        let data = res.data.result;
        if (data) {
          that.configForm = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getParameter: function (paraName) {
      let url = document.location.toString();
      //去除路由的#号
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      let arrObj = url.split("?");
      if (arrObj.length > 1) {
        let arrPara = arrObj[1].split("&");
        let arr;
        for (let i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");
          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    get: function (id) {
      let that = this;
      that.$http.get(that.base + "/content/get.do", {
        params: {
          id: id
        }
      }).then(function (data) {
        that.content = data.data.result
      }).catch(function (error) {

      })
    },
  }, created() {
    /*初始化卡片*/
    this.getConfigs(203);
    let id = this.getParameter("id");
    let type = this.getParameter("type");
    this.get(id, type);
    document.title = this.title;
  }
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
<style scoped>
.notice-left {
  width: 100% !important;
}
</style>

